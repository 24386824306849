/* eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{ cvCardTitle }}
            </h4>
          </template>
            <template v-slot:body>
          <ValidationObserver ref="carrForm">
              <p v-if="!propOpenedInModal">
                {{ cvCardSubHeader }}
              </p>
              <form action="#">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_field">
                      {{cvCarrFieldLabel}}</label>
                    <ValidationProvider
                        name="Career Field Name"
                        rules="required"
                        v-slot="{ errors }">
                      <multiselect
                        v-model="vmCareerCourseFormData.carr_field"
                        :options="cvCareerFieldOptions"
                        :placeholder="cvSelectBoxText + cvCarrFieldLabel"
                        label="carr_field"
                        track-by="carr_id"
                        required>
                      </multiselect>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_course_name">
                      {{cvCarrCourseNameLabel}}</label>
                      <ValidationProvider
                          name="Career Course Name"
                          rules="required"
                          v-slot="{ errors }">
                        <input
                          v-model="vmCareerCourseFormData.carr_course_name"
                          type="text"
                          class="form-control"
                          required/>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_course_degree">
                      {{cvCarrCourseDegreeLabel}}</label>
                      <ValidationProvider
                          name="Career Course Degree"
                          rules="required"
                          v-slot="{ errors }">
                            <input
                            v-model="vmCareerCourseFormData.carr_course_degree"
                            type="text"
                            class="form-control"
                            required/>
                          <span class="text-danger"> {{ errors[0] }} </span>
                        </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_course_degree_type">
                      {{cvCarrCourseDegreeTypeLabel}}</label>
                      <ValidationProvider
                          name="Career Course Degree Type"
                          rules="required"
                          v-slot="{ errors }">
                          <input
                            v-model="vmCareerCourseFormData.carr_course_degree_type"
                            type="text"
                            class="form-control"
                            required/>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_course_desc">
                      {{cvCarrCourseDescLabel}}</label>
                      <ValidationProvider
                          name="Career Course Description"
                          rules="required"
                          v-slot="{ errors }">
                    <vue-editor v-model="vmCareerCourseFormData.carr_course_desc"></vue-editor>
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row spinner_add">
                  <button v-if="cvLoadingStatus" type="button" class="btn btn-primary float-right" :disabled="cvLoadingStatus">
                    <b-spinner label="Spinning">
                    </b-spinner>
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="careerCourseAdd()">
                    {{ cvSubmitBtn }}
                  </button>
                </div>
              </form>
            </ValidationObserver>
            </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import Multiselect from "vue-multiselect"
import { CareerCourses } from "../../../FackApi/api/careerCourse"
import CareerUtility from "./CareerUtility"
import ApiResponse from "../../../Utils/apiResponse.js"
import { VueEditor } from "vue2-editor"
import { ValidationProvider } from "vee-validate"

export default {
  name: "CareerCourseAdd",
  components: {
    Multiselect,
    VueEditor,
    ValidationProvider
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cvSelectBoxText: "Select ",
      cvCardTitle: "Add Career Course",
      cvCardSubHeader: "Add Career Course ",
      cvSubmitBtn: "Add",
      cvCarrFieldLabel: "Career Field",
      cvCarrImageLabel: "career Image",
      cvCarrCourseNameLabel: "Career Course Name",
      cvCarrCourseDescLabel: "Career Course Desc",
      cvCarrCourseDegreeLabel: "Career Course Degree",
      cvCarrCourseDegreeTypeLabel: "Career Course Degree Type",
      cvCareerFieldOptions: [],
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "Career Course",
      vmCareerCourseFormData: Object.assign({}, this.initFormData()),
      vmCareerCourseDesc: null,
      vmCareerCourseType: null
    }
  },
  async mounted () {
    // this.vmCareerCourseFormData.carr_field = this.vmCareerCourseFormData.carr_field.carr_field
    this.cvCareerFieldOptions = await this.loadRequiredData()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "carr_field": "",
        "carr_course_name": "",
        "carr_course_desc": "",
        "carr_course_degree": "",
        "carr_course_degree_type": ""
      }
    },
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      return CareerUtility.careerFieldList(this)
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmCareerCourseFormData) {
          if (!this.vmCareerCourseFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * careerCourseAdd
     */
    async careerCourseAdd () {
      let valid = await this.$refs.carrForm.validate()
      if (!valid) {
        return
      }
      try {
        this.cvLoadingStatus = true
        this.vmCareerCourseFormData.carr_field = this.vmCareerCourseFormData.carr_field.carr_field
        let careerCourseAddResp = await CareerCourses.careerCourseAdd(this, this.vmCareerCourseFormData)
        await ApiResponse.responseMessageDisplay(this, careerCourseAddResp)
        if (careerCourseAddResp && !careerCourseAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseCareerCourseAddModal", careerCourseAddResp.resp_data)
        }
        this.vmCareerCourseFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at careerCourseAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
